import axios from "axios";
import SuperTokens from "supertokens-website";
import { v1 as uuidv1 } from "uuid";
import Cookies from "js-cookie";
import "cookieconsent/build/cookieconsent.min";
import { appendCSSViaPath, getSessionUserId } from "./utils";
// import posthog from 'posthog-js';
import { POSTHOG_INSTANCE_ADDRESS, POSTHOG_PROJECT_API_KEY } from "./constants";
import { analyticsConfig } from "./config";
import { buttonClickType, elementHoverType, sectionViewType } from "./constants";
import { trackEvent, identifyUser } from "./amplitude";

let API_URL;
let API_DOMAIN;
let API_BASE_PATH;
if (window.location.hostname === "supertokens.com" || window.location.hostname === "www.supertokens.com") {
    API_URL = "https://api.supertokens.com/0";
    API_DOMAIN = "https://api.supertokens.com";
    API_BASE_PATH = "/0/auth";
} else {
    API_URL = "https://dev.api.supertokens.com/0";
    API_DOMAIN = "https://dev.api.supertokens.com";
    API_BASE_PATH = "/0/auth";
}
const VERSION = 0;
const defaultGetRequestConfig = {
    timeout: 20000,
    maxRedirects: 20,
    withCredentials: true,
    xsrfCookieName: "",
    xsrfHeaderName: ""
};

const antcsEndpointUrl = "https://api.supertokens.com/0/antcs/ents";
const antcsPageTimeEndpoint = "https://api.supertokens.com/0/antcs/website/page-time-spent";
const baseAntcsStorageKey = "st_antcs";
const antcsCookieName = "st_antcs"; // analytics cookie name
const antcsCookieOptions = {
    domain: ".supertokens.com",
    sameSite: "Lax",
    expires: 10000
};
let sessionExpiredStatusCode = 401;

// initialize posthog if not localhost
if (!["localhost", "127.0.0.1", "test.supertokens.com"].includes(window.location.hostname)) {
    try {
        // posthog.init(POSTHOG_PROJECT_API_KEY, {
        //     api_host: POSTHOG_INSTANCE_ADDRESS
        // });
    } catch (ignored) {}
}

SuperTokens.init({
    apiDomain: API_DOMAIN,
    apiBasePath: API_BASE_PATH,
    sessionExpiredStatusCode,
    preAPIHook: async context => {
        return {
            ...context,
            requestInit: {
                ...context.requestInit,
                headers: {
                    ...context.requestInit.headers,
                    "api-version": "0"
                }
            }
        };
    }
});

const hostnameWhitelisting = {
    "supertokens.com": true,
    "www.supertokens.com": true
};

const udidBlacklised = {
    "st-team-udid1234": true
};

const userConfig = {
    timeout: 20000,
    maxRedirects: 20,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
        "api-version": 0
    }
};

async function simpleGETRequest(url, userConfig, version) {
    userConfig = {
        ...userConfig,
        params: {
            ...userConfig.params
        }
    };
    userConfig = {
        ...defaultGetRequestConfig,
        ...userConfig,
        headers: {
            ...userConfig.headers,
            "api-version": version + ""
        }
    };
    let response = await axios.get(url, userConfig);
    let data = await response.data;
    let headers = response.headers;
    return { data, headers };
}

const pageAddressesToSendToSegment = {
    "/blog": {
        eventName: "page_blog"
    },
    "/blog/implementing-a-forgot-password-flow": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "implementing-a-forgot-password-flow"
        }
    },
    "/blog/the-real-reason-okta-spent-on-auth0": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "the-real-reason-okta-spent-on-auth0"
        }
    },
    "/blog/all-you-need-to-know-about-user-session-security": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "all-you-need-to-know-about-user-session-security"
        }
    },
    "/blog/the-best-way-to-securely-manage-user-sessions": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "the-best-way-to-securely-manage-user-sessions"
        }
    },
    "/blog/are-you-using-jwts-for-user-sessions-in-the-correct-way": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "the-best-way-to-securely-manage-user-sessions"
        }
    },
    "/blog/cookies-vs-localstorage-for-sessions-everything-you-need-to-know": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "cookies-vs-localstorage-for-sessions-everything-you-need-to-know"
        }
    },
    "/blog/express-session-vs-supertokens-for-handling-user-sessions": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "express-session-vs-supertokens-for-handling-user-sessions"
        }
    },
    "/blog/should-you-use-express-session-for-your-production-app": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "should-you-use-express-session-for-your-production-app"
        }
    },
    "/blog/speed-up-your-web-development-time-by-integrating-webflow-into-a-react-application": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "speed-up-your-web-development-time-by-integrating-webflow-into-a-react-application"
        }
    },
    "/blog/solve-the-problem-of-vendor-lock-in": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "solve-the-problem-of-vendor-lock-in"
        }
    },
    "/blog/why-is-redux-state-immutable": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "why-is-redux-state-immutable"
        }
    },
    "/blog/how-to-customise-supertokens-apis": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "how-to-customise-supertokens-apis"
        }
    },
    "/blog/adding-social-login-to-your-website-with-supertokens": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "adding-social-login-to-your-website-with-supertokens"
        }
    },
    "/blog/revoking-access-with-a-jwt-blacklist": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "revoking-access-with-a-jwt-blacklist"
        }
    },
    "/blog/how-to-set-up-social-and-email-password-login-with-reactjs": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "how-to-set-up-social-and-email-password-login-with-reactjs"
        }
    },
    "/blog/password-hashing-salting": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "password-hashing-salting"
        }
    },
    "/blog/building-a-login-screen-with-react-and-bootstrap": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "building-a-login-screen-with-react-and-bootstrap"
        }
    },
    "/blog/what-is-jwt": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "what-is-jwt"
        }
    },
    "/blog/oauth-vs-jwt": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "oauth-vs-jwt"
        }
    },
    "/blog/how-to-deploy-supertokens-with-react-nodejs-express-on-vercel": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "how-to-deploy-supertokens-with-react-nodejs-express-on-vercel"
        }
    },
    "/blog/connect-supertokens-to-database": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "connect-supertokens-to-database"
        }
    },
    "/blog/auth0-alternatives-auth0-vs-okta-vs-cognito-vs-supertokens": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "auth0-alternatives-auth0-vs-okta-vs-cognito-vs-supertokens"
        }
    },
    "/blog/how-to-use-supertokens-pre-built-ui-with-vuejs": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "how-to-use-supertokens-pre-built-ui-with-vuejs"
        }
    },
    "/blog/user-roles": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "user-roles"
        }
    },
    "/blog/passwordless-for-product-managers": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "passwordless-for-product-managers"
        }
    },
    "/blog/how-to-use-supertokens-custom-ui-with-vuejs": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "how-to-use-supertokens-custom-ui-with-vuejs"
        }
    },
    "/blog/supabase-auth-comparison-with-supertokens-integration": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "supabase-auth-comparison-with-supertokens-integration"
        }
    },

    "/ambassadors": {
        eventName: "page_ambassadors"
    },
    "/pricing": {
        eventName: "page_pricing"
    },
    "/": {
        eventName: "page_home",
        version: "v6"
    },
    "/home": {
        eventName: "page_home"
    },
    "/consultancy": {
        eventName: "page_consultancy"
    },
    "/use-oss": {
        eventName: "page_use-oss"
    },
    "/product-roadmap": {
        eventName: "page_productroadmap"
    },
    "/docs/contribute/introduction": {
        eventName: "page_docs_contribute_introduction"
    },
    "/docs/guides": {
        eventName: "page_documentation_userguides"
    },
    "/docs/references": {
        eventName: "page_documentation_references"
    },
    "/docs/emailpassword/introduction": {
        eventName: "page_docs_emailpassword_introduction"
    },
    "/docs/emailpassword/quick-setup/overview": {
        eventName: "page_docs_emailpassword_quick-setup_overview"
    },
    "/docs/emailpassword/quick-setup/frontend": {
        eventName: "page_docs_emailpassword_quick-setup_frontend"
    },
    "/docs/emailpassword/quick-setup/backend": {
        eventName: "page_docs_emailpassword_quick-setup_backend"
    },
    "/docs/guides": {
        eventName: "page_documentation_userguides"
    },
    "/docs/references": {
        eventName: "page_documentation_references"
    }
};

class Antcs {
    constructor() {
        this.userId = null;
        this.pageViewEventSent = false;

        const consentCookie = Cookies.get("cookieconsent_status");
        if (consentCookie === undefined) {
            this.cookieAllowed = undefined;
        } else if (consentCookie === "deny") {
            this.cookieAllowed = false;
        } else if (consentCookie === "allow") {
            this.cookieAllowed = true;
        }
    }

    /** @param {boolean} consent */

    setCookieConsent(consent) {
        this.cookieAllowed = consent;
        if (consent) {
            Cookies.set("cookieconsent_status", "allow");
        } else {
            Cookies.set("cookieconsent_status", "deny");
        }
    }

    getUserId() {
        let userIdInLocalStorage = localStorage === null ? null : localStorage.getItem(baseAntcsStorageKey);

        if (userIdInLocalStorage === "st-team-udid1234") {
            return userIdInLocalStorage;
        }

        if (this.cookieAllowed !== false) {
            const valueInCookie = Cookies.get(antcsCookieName);
            if (valueInCookie !== undefined) {
                return valueInCookie;
            }
            if (userIdInLocalStorage !== null) {
                Cookies.set(antcsCookieName, userIdInLocalStorage, antcsCookieOptions);
                localStorage.removeItem(baseAntcsStorageKey);
                return userIdInLocalStorage;
            }

            const newUserId = uuidv1();
            Cookies.set(antcsCookieName, newUserId, antcsCookieOptions);
            return newUserId;
        }
        return "untracked_user";
    }

    shouldSendEventsToApi() {
        const hostname = window.location.hostname;
        const userId = this.getCommonData().userId;
        if (
            (hostnameWhitelisting[hostname] === true || hostname.endsWith(".demo.supertokens.com")) &&
            udidBlacklised.hasOwnProperty(userId) === false
        ) {
            return true;
        }
        return false;
    }

    getCommonData() {
        if (this.userId === null) {
            // If userId is null, that means segment is not initialized since we give segement a user id
            this.userId = this.getUserId();
        }
        const userId = this.userId;
        return {
            page: window.location.href,
            userId: userId
        };
    }

    async sendGenericEvent(eventName, userData, analyticsVersion = "v5") {
        this.sendEvent(eventName, userData, analyticsVersion);
    }

    async sendEvent(eventName, userData, analyticsVersion) {
        if (this.cookieAllowed !== false) {
            const version = analyticsVersion;
            const commonData = this.getCommonData();
            const sessionUserId = await getSessionUserId();
            const timestamp = Date.now();
            const data = {
                timestamp,
                version,
                sessionUserId,
                ...commonData,
                ...userData
            };
            // send event data to posthog
            try {
                // posthog.capture(eventName, { ...data });
            } catch (ignored) {}

            if (this.shouldSendEventsToApi()) {
                if (eventName !== "page_view") {
                    trackEvent(eventName, data);
                }
                // since we don't care about the data returned, we don't need to use await
                // since we aren't using await, this shouldn't stop program as well if in case one of the api or function fails
                const payload = {
                    eventName: eventName,
                    data
                };
                axios
                    .post(antcsEndpointUrl, payload, userConfig)
                    .then(() => {
                        this.pageViewEventSent = true; // this is only for redirect links and only for page_view events
                    })
                    .catch(error => {
                        this.pageViewEventSent = true; // this is only for redirect links and only for page_view events
                    });
            } else {
                this.pageViewEventSent = true;
                // Do not remove this console logs as it's used on test and localhost site
                console.log(eventName, data);
            }
            return;
        }
    }

    identifyAmplitudeUser(properties) {
        identifyUser(properties);
    }

    async sendButtonEvent(eventName, userData) {
        await this.sendEvent(
            eventName,
            {
                type: "button_click",
                ...userData
            },
            "v1"
        );
    }

    async sendPageViewEventToSegment() {
        const pageAddress = window.location.pathname;
        const info = pageAddressesToSendToSegment[pageAddress];
        const defaultEventOptions = {
            type: "page_view",
            referrer: document.referrer
        };

        const analyticsVersion = info === undefined || info.version === undefined ? "v5" : info.version;

        if (info !== undefined && info.eventName !== undefined) {
            this.sendEvent(info.eventName, { ...defaultEventOptions, ...info.metaData }, analyticsVersion);
        }
        if (pageAddress.startsWith("/docs/")) {
            this.sendEvent("page_docs_visited", defaultEventOptions, analyticsVersion);
        }
    }

    async sendSupertokensSDKLogs(data) {
        const version = "v1";
        const commonData = this.getCommonData();
        const sessionUserId = await getSessionUserId();
        const timestamp = Date.now();
        const metaData = {
            timestamp,
            version,
            sessionUserId,
            ...commonData
        };

        if (this.shouldSendEventsToApi()) {
            try {
                await axios.post(
                    antcsEndpointUrl,
                    {
                        eventName: "auth_error_sdk_logs",
                        data: {
                            type: "auth_error_sdk_logs",
                            ...metaData,
                            ...data
                        }
                    },
                    {}
                );
            } catch (e) {
                // ignored
            }
        } else {
            console.log({
                eventName: "auth_error_sdk_logs",
                data: {
                    ...metaData,
                    ...data
                }
            });
        }
    }

    async sendPageViewEvents() {
        await this.sendEvent(
            "page_view",
            {
                type: "page_view",
                referrer: document.referrer
            },
            "v5"
        );
    }
    // https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
    addPageTimeAnalytics() {
        if (this.cookieAllowed !== false) {
            const commonData = this.getCommonData();
            let startTime = Date.now();

            let hidden, visibilityChange;
            if (typeof document.hidden !== "undefined") {
                hidden = "hidden";
                visibilityChange = "visibilitychange";
            } else if (typeof document.msHidden !== "undefined") {
                hidden = "msHidden";
                visibilityChange = "msvisibilitychange";
            } else if (typeof document.webkitHidden !== "undefined") {
                hidden = "webkitHidden";
                visibilityChange = "webkitvisibilitychange";
            }
            const hideHandler = async () => {
                if (document[hidden]) {
                    const timeElapsed = Date.now() - startTime;
                    const data = {
                        timeElapsed,
                        pageURL: commonData.page,
                        userId: commonData.userId
                    };

                    if (!navigator.sendBeacon) {
                        return; // synchronous xhr required, hurts UX
                    } else if (this.shouldSendEventsToApi()) {
                        // sendBeacon only supports CORS safelisted headers
                        const blob = new Blob([JSON.stringify(data)], { type: "application/json" });
                        navigator.sendBeacon(antcsPageTimeEndpoint, blob);
                    }
                } else {
                    let temp = Date.now();
                    while (Date.now() - temp < 1000) {
                        if (!document[hidden]) {
                            startTime = Date.now();
                            break;
                        }
                        await new Promise(r => setTimeout(r, 100));
                    }
                }
            };
            document.addEventListener(visibilityChange, hideHandler);
            // TODO: delete pagehide handler when visibilitychange api support is consistent across browsers
            document.addEventListener("pagehide", hideHandler);
        }
    }

    addAutomaticAnalytics() {
        const defaultAnalyticsVersion = "v6";

        function getAnalyticsVersionForConfig(config) {
            if (config === undefined || config.analyticsVersion === undefined) {
                return defaultAnalyticsVersion;
            }

            return config.analyticsVersion;
        }

        /**
         * This reads from the analytics config to find all element ids that need
         * analytics configured for them. For each element it loops through the array of events
         * and then adds some event listener depending on the type of event
         *
         * The setTimeout it to ensure that this runs after the first meaningful draw
         */
        document.addEventListener("DOMContentLoaded", () => {
            setTimeout(() => {
                Object.keys(analyticsConfig).forEach(elementId => {
                    const element = document.getElementById(elementId);

                    if (element !== null && element !== undefined) {
                        const configsForId = analyticsConfig[elementId];

                        // Config must be an array
                        if (Array.isArray(configsForId)) {
                            configsForId.forEach(config => {
                                const type = config["type"];

                                if (type !== undefined) {
                                    let metadata = config["metadata"];
                                    metadata = {
                                        ...metadata,
                                        type
                                    };

                                    let touchTarget = undefined;

                                    if (type === buttonClickType) {
                                        element.addEventListener("touchstart", event => {
                                            touchTarget = event.target === null ? undefined : event.target;
                                            this.sendEvent(
                                                config.eventName,
                                                metadata === undefined ? {} : metadata,
                                                getAnalyticsVersionForConfig(config)
                                            );
                                        });

                                        element.addEventListener("click", event => {
                                            if (event.target === touchTarget) {
                                                touchTarget = undefined;
                                                return;
                                            }

                                            this.sendEvent(
                                                config.eventName,
                                                metadata === undefined ? {} : metadata,
                                                getAnalyticsVersionForConfig(config)
                                            );
                                        });
                                    } else if (type === elementHoverType) {
                                        let lastHoveredElement = undefined;

                                        element.addEventListener("mouseenter", event => {
                                            lastHoveredElement = event.target === null ? undefined : event.target;

                                            // We only consider something hovered if the mouse stays for 200
                                            setTimeout(() => {
                                                if (event.target === lastHoveredElement) {
                                                    this.sendEvent(
                                                        config.eventName,
                                                        metadata,
                                                        getAnalyticsVersionForConfig(config)
                                                    );
                                                }
                                            }, 200);
                                        });

                                        element.addEventListener("mouseleave", event => {
                                            if (event.target === lastHoveredElement) {
                                                lastHoveredElement = undefined;
                                            }
                                        });
                                    } else if (type === sectionViewType) {
                                        // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
                                        const observerOptions = {
                                            root: null, // observing relative to viewport
                                            rootMargin: "0px",
                                            threshold: 0.2 // heading should be completely visible
                                        };

                                        let isEventAlreadySent = {};

                                        const onIntersection = (entries, observer) => {
                                            entries.forEach(entry => {
                                                const id = entry.target.id;

                                                if (isEventAlreadySent[id] === true) {
                                                    const elementToUnobserve = document.getElementById(id);
                                                    if (elementToUnobserve !== null) {
                                                        observer.unobserve(elementToUnobserve);
                                                    }
                                                    return;
                                                }
                                                if (entry.isIntersecting) {
                                                    isEventAlreadySent[id] = true;
                                                    this.sendEvent(
                                                        config.eventName,
                                                        metadata,
                                                        getAnalyticsVersionForConfig(config)
                                                    );
                                                }
                                            });
                                        };

                                        let observer = new IntersectionObserver(onIntersection, observerOptions);
                                        observer.observe(element);
                                    }
                                }
                            });
                        }
                    }
                });
            }, 1000);
        });
    }
}

function getInstance() {
    if (Antcs.instance === undefined) {
        Antcs.instance = new Antcs();
    }
    return Antcs.instance;
}

function addTwitterTracking() {
    !(function(e, t, n, s, u, a) {
        e.twq ||
            ((s = e.twq = function() {
                s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
            }),
            (s.version = "1.1"),
            (s.queue = []),
            (u = t.createElement(n)),
            (u.async = !0),
            (u.src = "//static.ads-twitter.com/uwt.js"),
            (a = t.getElementsByTagName(n)[0]),
            a.parentNode.insertBefore(u, a));
    })(window, document, "script");
    // Insert Twitter Pixel ID and Standard Event data below
    twq("init", "o563l");
    twq("track", "PageView");
}

const IP_INFO_URL = API_URL + "/ip/info";
async function getUserIpInfo() {
    let options = {
        timeout: 10000
    };

    let response = await (await simpleGETRequest(IP_INFO_URL, options, VERSION)).data;
    return response;
}

let popUp;
function addCookieConsentBanner() {
    const cssPath = "/static/cookieconsent.min.css";

    const shouldLoadCookieConsentCSS = Array.from(window.document.getElementsByTagName("link")).some(
        link => !link.href.includes(cssPath)
    );

    if (shouldLoadCookieConsentCSS) {
        appendCSSViaPath(cssPath);
    }

    window.cookieconsent.initialise(
        {
            palette: {
                popup: {
                    background: "#000"
                },
                button: {
                    background: "#FF9933"
                }
            },
            content: {
                message:
                    "This website uses cookies to improve your experience. Please click decline to disable tracking cookies.",
                link: "Privacy Policy",
                href: "https://supertokens.com/legal/privacy-policy"
            },
            type: "opt-out",
            cookie: {
                expiryDays: 3650
            },
            position: "bottom-left",
            onPopupOpen: function() {
                addDismissButton();
            },
            onPopupClose: function() {
                document.body.style.paddingBottom = "0px";
                document.getElementById("ccbtn-dismisscookie").remove();
                document.querySelector(".cc-revoke").remove();
            },
            onStatusChange: function(status) {
                if (status === "deny") {
                    getInstance().setCookieConsent(false);
                }
            }
        },
        function(popup) {
            popUp = popup;
        }
    );
}

function addDismissButton() {
    const closeButton = document.createElement("button");
    closeButton.id = "ccbtn-dismisscookie";
    closeButton.innerHTML = `<a href="#" class="cc-close-icon"> `;
    const container = document.querySelector(".cc-window");
    container.prepend(closeButton);
}

function startMainWebsiteAnalytics() {
    getInstance().addAutomaticAnalytics();
    // addTwitterTracking();
    getInstance().sendPageViewEvents();
    getInstance().sendPageViewEventToSegment();
    getInstance().addPageTimeAnalytics();
}

// if we do not know that the user has previously accepted / rejected the cookie banner...
if (getInstance().cookieAllowed === undefined) {
    // show banner
    const consentCookie = Cookies.get("cookieconsent_status");
    if (consentCookie === undefined) {
        // consent does not exist, ask for consent and if the user declines
        // disable tracking.
        addCookieConsentBanner(); // handles tracking based on whether user dismisses, accepts or allows cookies
        const cookiePopupDismissBtn = document.getElementById("ccbtn-dismisscookie");
        if (cookiePopupDismissBtn !== null) {
            cookiePopupDismissBtn.onclick = function(e) {
                popUp.setStatus(cookieconsent.status.dismiss);
                popUp.close();
            };
        }
    } else if (consentCookie === "deny") {
        // user has already denied the tracking
        getInstance().setCookieConsent(false);
    }
}

if (getInstance().cookieAllowed !== false) {
    startMainWebsiteAnalytics();
}

export { getInstance };
