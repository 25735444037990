import { buttonClickType, elementHoverType, sectionViewType } from "./constants";
import { jwtDecoderAnalyticsConfig } from "./jwtDecoderAnalyticsConfig";

/**
 * "element-id": [
 *      {
 *          type: buttonClickType,
 *          metadata: {},
 *      },
 * ]
 */
export const analyticsConfig = {
    // Add all events from the jwt decoder tool
    ...jwtDecoderAnalyticsConfig,
    "full-case-study-pronto": [
        {
            type: buttonClickType,
            eventName: "button_case_study_viewfullvideo",
            metadata: {
                "option_clicked": "Go Pronto",
            }
        },
    ],
    "full-case-study-poppy": [
        {
            type: buttonClickType,
            eventName: "button_case_study_viewfullvideo",
            metadata: {
                "option_clicked": "Poppy",
            }
        },
    ],
    "full-case-study-builtintelligence": [
        {
            type: buttonClickType,
            eventName: "button_case_study_viewfullvideo",
            metadata: {
                "option_clicked": "Built Intelligence",
            }
        },
    ],
    "pronto": [
        {
            type: buttonClickType,
            eventName: "button_case_study_view",
            metadata: {
                "option_clicked": "Go Pronto",
            }
        },
    ],
    "poppy": [
        {
            type: buttonClickType,
            eventName: "button_case_study_view",
            metadata: {
                "option_clicked": "Poppy",
            }
        },
    ],
    "builtintelligence": [
        {
            type: buttonClickType,
            eventName: "button_case_study_view",
            metadata: {
                "option_clicked": "Built Intelligence",
            }
        },
    ],
    "last-section-gopronto": [
        {
            type: sectionViewType,
            eventName: "page_case_study_lastsectionviewed",
            metadata: {
                "page_viewed": "Go Pronto"
            }
        },
    ],
    "last-section-poppy": [
        {
            type: sectionViewType,
            eventName: "page_case_study_lastsectionviewed",
            metadata: {
                "page_viewed": "Poppy"
            }
        },
    ],
    "last-section": [
        {
            type: sectionViewType,
            eventName: "page_case_study_lastsectionviewed",
            metadata: {
                "page_viewed": "Built Intelligence"
            }
        },
    ],
    "copy_command_cli_landing": [
        {
            type: buttonClickType,
            eventName: "button_home_clicommand_copied",
            metadata: {
                "section_copied": "landing section",
            }
        },
    ],
    "copy_command_cli_under1minute": [
        {
            type: buttonClickType,
            eventName: "button_home_clicommand_copied",
            metadata: {
                "section_copied": "cli command section",
            }
        },
    ],
    "stats_github_cta": [
        {
            type: buttonClickType,
            eventName: "button_section_stats_cta",
            metadata: {
                "option_clicked": "Star Us",
            }
        },
    ],
    "stats_join_discord_cta": [
        {
            type: buttonClickType,
            eventName: "button_section_stats_cta",
            metadata: {
                "option_clicked": "Join Discord",
            }
        },
    ],
    "stats_join_discord_cta": [
        {
            type: buttonClickType,
            eventName: "button_section_stats_cta",
            metadata: {
                "option_clicked": "Join Discord",
            }
        },
    ],
    "email_password_cta": [
        {
            type: buttonClickType,
            eventName: "button_recipes_getstarted_cta",
            metadata: {
                "option_clicked": "Email password",
            }
        },
    ],
    "social_login_cta": [
        {
            type: buttonClickType,
            eventName: "button_recipes_getstarted_cta",
            metadata: {
                "option_clicked": "Social Login",
            }
        },
    ],
    "third_party_email_password_cta": [
        {
            type: buttonClickType,
            eventName: "button_recipes_getstarted_cta",
            metadata: {
                "option_clicked": "Third Party Email Password",
            }
        },
    ],
    "passwordless_cta": [
        {
            type: buttonClickType,
            eventName: "button_recipes_getstarted_cta",
            metadata: {
                "option_clicked": "Passwordless",
            }
        },
    ],
    "github_icon_sidebar": [
        {
            type: buttonClickType,
            eventName: "button_sidebar_socialmediaprofile",
            metadata: {
                "option_clicked": "Github",
            }
        },
    ],
    "discord_icon_sidebar": [
        {
            type: buttonClickType,
            eventName: "button_sidebar_socialmediaprofile",
            metadata: {
                "option_clicked": "Discord",
            }
        },
    ],
    "twitter_icon_sidebar": [
        {
            type: buttonClickType,
            eventName: "button_sidebar_socialmediaprofile",
            metadata: {
                "option_clicked": "Twitter",
            }
        },
    ],
    "hiring-cta-bottom": [
        {
            type: buttonClickType,
            eventName: "button_home_banner_cta",
            metadata: {
                "option_clicked": "open position angellist",
            }
        },
    ],
    "github-star-banner": [
        {
            type: buttonClickType,
            eventName: "button_home_banner_cta",
            metadata: {
                "option_clicked": "github star",
            }
        },
    ],
    "bottom_landing_discord": [
        {
            type: buttonClickType,
            eventName: "button_home_bottomlanding_cta",
            metadata: {
                "option_clicked": "discord",
            }
        },
    ],
    "cta_docs_bottom_landing": [
        {
            type: buttonClickType,
            eventName: "button_home_bottomlanding_cta",
            metadata: {
                "option_clicked": "docs cta",
            }
        },
    ],
    "poppy_logo": [
        {
            type: elementHoverType,
            eventName: "button_home_userslogos_hovered",
            metadata: {
                "option_hovered": "Poppy.com",
            }
        },
    ],
    "salad_logo": [
        {
            type: elementHoverType,
            eventName: "button_home_userslogos_hovered",
            metadata: {
                "option_hovered": "Salad.com",
            }
        },
    ],
    "serifhealth_logo": [
        {
            type: elementHoverType,
            eventName: "button_home_userslogos_hovered",
            metadata: {
                "option_hovered": "Serifhealth.com",
            }
        },
    ],
    "foodmarkethub_logo": [
        {
            type: elementHoverType,
            eventName: "button_home_userslogos_hovered",
            metadata: {
                "option_hovered": "Foodmarkethub.com",
            }
        },
    ],
    "skoot_logo": [
        {
            type: elementHoverType,
            eventName: "button_home_userslogos_hovered",
            metadata: {
                "option_hovered": "Skoot.com",
            }
        },
    ],
    "hyperbeam_logo": [
        {
            type: elementHoverType,
            eventName: "button_home_userslogos_hovered",
            metadata: {
                "option_hovered": "Hyperbeam.com",
            }
        },
    ],
    "landing_section": [
        {
            type: sectionViewType,
            eventName: "page_home_section_viewed",
            metadata: {
                "section_viewed": "Landing Section"
            }
        },
    ],
    "trusted_by_logos_section": [
        {
            type: sectionViewType,
            eventName: "page_home_section_viewed",
            metadata: {
                "section_viewed": "Trusted by section"
            }
        },
    ],
    "setup_section": [
        {
            type: sectionViewType,
            eventName: "page_home_section_viewed",
            metadata: {
                "section_viewed": "Setup section"
            }
        },
    ],
    "feature_toggle_section": [
        {
            type: sectionViewType,
            eventName: "page_home_section_viewed",
            metadata: {
                "section_viewed": "Feature toggle section"
            }
        },
    ],
    "cli_command_section": [
        {
            type: sectionViewType,
            eventName: "page_home_section_viewed",
            metadata: {
                "section_viewed": "cli command section"
            }
        },
    ],
    "multitenancy-coming-soon": [
        {
            type: sectionViewType,
            eventName: "page_home_section_viewed",
            metadata: {
                "section_viewed": "coming soon section"
            }
        },
    ],
    "testimonials_section": [
        {
            type: sectionViewType,
            eventName: "page_home_section_viewed",
            metadata: {
                "section_viewed": "user testimonials section"
            }
        },
    ],
    "stats_section": [
        {
            type: sectionViewType,
            eventName: "page_home_section_viewed",
            metadata: {
                "section_viewed": "stats section"
            }
        },
    ],
    "recipe_text-section": [
        {
            type: sectionViewType,
            eventName: "page_home_section_viewed",
            metadata: {
                "section_viewed": "recipe text section"
            }
        },
    ],
    "recipe_content_section": [
        {
            type: sectionViewType,
            eventName: "page_home_section_viewed",
            metadata: {
                "section_viewed": "recipe content section"
            }
        },
    ],
    "all-about-supertokens-video": [
        {
            type: sectionViewType,
            eventName: "page_home_section_viewed",
            metadata: {
                "section_viewed": "all about supertokens video section"
            }
        },
    ],
    "bottom_ending_section": [
        {
            type: sectionViewType,
            eventName: "page_home_section_viewed",
            metadata: {
                "section_viewed": "Bottom Section"
            }
        },
    ],
}