import { buttonClickType } from "./constants";

// Refer to types in ./config.js
export const jwtDecoderAnalyticsConfig = {
    "jwt-decoder-copy-btn": [
        {
            type: buttonClickType,
            eventName: "button_jwt-encoder-decoder_cta",
            metadata: {
                "button_text": "Copy JWT"
            }
        }
    ]
};