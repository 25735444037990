import SuperTokens from "supertokens-website";

export const appendCSSViaPath = (path) => {
    const link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = path;
    document.head.appendChild(link);
}

export const getSessionUserId = async ()=>{
    const doesSessionExist = await SuperTokens.doesSessionExist();
    
    if(doesSessionExist){
        const userId = await SuperTokens.getUserId()
        return userId
    }
    return null;
}