import * as amplitude from "@amplitude/analytics-browser";

let amplitudeInitialized = false;

export const initAmplitude = () => {
    if (amplitudeInitialized) {
        return;
    }
    amplitude.init("b61f581fe0775f56038a6264af879aa9", {
        logLevel: amplitude.Types.LogLevel.error,
        autocapture: {
            attribution: true,
            pageViews: true,
            sessions: true,
            formInteractions: true,
            fileDownloads: true,
            elementInteractions: true
        }
    });
    amplitudeInitialized = true;
};

export const trackEvent = (event, properties) => {
    if (!amplitudeInitialized) {
        initAmplitude();
    }
    amplitude.track(event, properties);
};

const identifyEvent = new amplitude.Identify();

export const identifyUser = properties => {
    if (!amplitudeInitialized) {
        initAmplitude();
    }

    for (const [key, value] of Object.entries(properties)) {
        identifyEvent.set(key, value);
    }

    amplitude.identify(identifyEvent);
};

export const isAmplitudeInitialized = () => {
    return amplitudeInitialized;
};
